body{
  background-color: #282c34;
    color: white;
}
.App {
  padding: 20px 40px;
  background-color: #282c34;
  color: white;
}


.App-header {
  background-color: #282c34;
  display: flex;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
